import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Cta from '../../components/cta'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

const KombinovaneMasazePage = ({data}) => (
  <Layout>
    <SEO title="Služby masážního salonu Vimperk" />

    <div className="container">
      <h1 className="pb-1">Kombinované masáže</h1>

      <p>
        Na začátku našeho sezení probereme Vaše potřeby a&nbsp;z&nbsp;více než
        30&nbsp;procedur vytvoříme kombinaci právě pro&nbsp;Vás.
        <br />
        Doporučujeme vyhradit&nbsp;si čas aspoň na&nbsp;hodinovou masáž.
      </p>

      <div className="uk-grid uk-child-width-expand pb-2">
        <div>
          <ul>
            <li>Bambusová masáž,</li>
            <li>masáž lávovými kameny,</li>
            <li>antistresová masáž hlavy a&nbsp;šíje,</li>
            {/* <li>masáž obličeje a&nbsp;lifting,</li> */}
            <li>masáž proti pocitu těžkých nohou,</li>
            <li>baňkování,</li>
            <li>manuální lymfatická masáž,</li>
            <li>relaxační masáže,</li>
            <li>reflexní terapie,</li>
            <li>masáž plosek a dlaní.</li>
          </ul>

          <p className="price">450 Kč / 30&nbsp;minut</p>
        </div>

        <div>
          <Img fluid={data.file.childImageSharp.fluid} objectFit="cover" />
        </div>
      </div>

      <Cta before={<Link className="link" to="/sluzby">Zpět na všechny služby</Link>} />
    </div>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "lava.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default KombinovaneMasazePage
